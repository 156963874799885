import {Editor, type OnMount} from "@monaco-editor/react";
import {editor, Uri} from "monaco-editor";
import BetterWebSocket from "partysocket/ws";
import React from "react";
import {MonacoBinding} from "y-monaco";
import YPartyKitProvider from "y-partykit/provider";
import * as Y from "yjs";

import * as monaco from "monaco-editor";
import {editorModelOptions, VariableTheme} from "./constants";
import {Link, useParams} from "react-router-dom";


declare const PARTYKIT_HOST: string;


// window.MonacoEnvironment = {
//     getWorkerUrl: function(moduleId, label) {
//         if (label === "json") {
//             // return "/dist/json.worker.bundle.js";
//             return "/dist/monaco-editor/esm/vs/language/json/json.js";
//         }
//         if (label === "css" || label === "scss" || label === "less") {
//             return "/dist/monaco-editor/esm/vs/language/css/css.js";
//             // return "/dist/css.worker.bundle.js";
//         }
//         if (label === "html" || label === "handlebars" || label === "razor") {
//             return "/dist/monaco-editor/esm/vs/language/html/html.js";
//             // return "/dist/html.worker.bundle.js";
//         }
//         if (label === "typescript" || label === "javascript") {
//             // return "/dist/ts.worker.bundle.js";
//             return "/dist/monaco-editor/esm/vs/language/typescript/ts.js";
//         }
//         return "/dist/monaco-editor/esm/vs/editor/editor.worker.js";
//         // return "/dist/editor.worker.bundle.js";
//     },
// };

function AppEditorWithID() {
    const {mashupId, componentName} = useParams();


    if (!mashupId) {
        return <div>No room id</div>;
    }

    return <div className="font-sans w-screen h-screen flex flex-1 justify-center items-start flex-row my-1 px-4 gap-x-4">

        <div className="flex  max-w-80  bg-[#111111] p-5 rounded-3xl flex-col gap-5 h-full">
            <div className="flex flex-1 flex-col gap-3 justify-between">

                <div className="flex flex-col gap-3">
                    {["Root", "Button", "Input", "Text", "Link", "main", "ab"].map(
                        (item) => item === mashupId ?
                            <div
                                key={item}
                                className="text-sm text-left"
                            >{item}</div> :
                            <Link
                                key={item}
                                className="text-sm text-left hover:opacity-100 opacity-50 transition-opacity duration-150"
                                to={`/${mashupId}/${item}`}
                            >{item}
                            </Link>)}

                </div>
                <button
                    className="text-sm text-left text-blue-500 hover:text-blue-600 transition-colors duration-150"
                >
                Add Component
                </button>
            </div>


        </div>
        <AppEditor roomId={`${mashupId}_${componentName}`} />
    </div>;
}


function getProvider(roomId: string): {
    provider: YPartyKitProvider;
    type: Y.Text;
} {
    console.log("getProvider", roomId);
    const ydoc = new Y.Doc();
    const type = ydoc.getText(roomId);

    return {
        provider: new YPartyKitProvider(PARTYKIT_HOST, roomId, ydoc, {
            // @ts-expect-error TODO: fix this
            WebSocketPolyfill: BetterWebSocket,
        }),
        type,
    };
}

function AppEditor({roomId}: {roomId: string}) {
    console.log("AppEditor", roomId);
    const monacoRoot = React.useRef<typeof monaco | null>(null);
    const monacoEditor = React.useRef<editor.IStandaloneCodeEditor | null>(null);
    const providerDetails = React.useRef<{provider: YPartyKitProvider, type: Y.Text}>(getProvider(roomId));
    const binding = React.useRef<MonacoBinding | null>(null);

    React.useEffect(() => {
        if (providerDetails.current?.provider.roomname !== roomId) {
            providerDetails.current.provider.awareness.destroy();
            providerDetails.current.provider.disconnect();
            providerDetails.current.provider.destroy();

            binding.current?.destroy();

            if (!monacoEditor.current || !monacoRoot.current) {
                console.error("No monaco editor");
                return;
            }

            console.log("setting new provider");

            providerDetails.current = getProvider(roomId);


            const newModel = monacoRoot.current.editor.getModel(Uri.parse(`file:///${roomId}.json`)) || createModel(roomId, monacoRoot.current.editor);

            if (newModel && monacoEditor.current) {
                monacoEditor.current.setModel(newModel);
            } else {
                console.error("No model");
                return;
            }

            console.log("newModel", newModel);

            binding.current = new MonacoBinding(
                providerDetails.current.type,
                // standaloneEditor.getModel()!,
                newModel,
                new Set([monacoEditor.current]),
                providerDetails.current.provider.awareness
            );
        }
    }, [roomId]);


    // const [ binding, setBinding ] = React.useState<MonacoBinding | null>(null);
    // const provider = useYProvider({
    //     host: PARTYKIT_HOST,
    //     room: roomId,
    //     doc: ydoc,

    //     // @ts-expect-error TODO: fix this
    //     WebSocketPolyfill: BetterWebSocket,
    // });


    const onEditorDidMount: OnMount = React.useCallback((standaloneEditor, monaco) => {
        console.log("onEditorDidMount", standaloneEditor, monaco);
        monacoRoot.current = monaco;
        monacoEditor.current = standaloneEditor;

        const active = monaco.editor.getModel(Uri.parse(`file:///${roomId}.json`));

        if (active) {
            active.dispose();
        }


        const newModel = createModel(roomId, monaco.editor);

        if (newModel) {
            monacoEditor.current.setModel(newModel);
        } else {
            console.error("No model");
            return;
        }
        console.log("newModel", newModel);

        binding.current = new MonacoBinding(
            providerDetails.current.type,
            // standaloneEditor.getModel()!,
            newModel,
            new Set([standaloneEditor]),
            providerDetails.current.provider.awareness
        );

        monaco.editor.defineTheme("variableTheme", VariableTheme);
        monaco.editor.setTheme("variableTheme");
    }, []);

    return <div className="px-5 py-4 rounded-3xl overflow-hidden bg-[#111111] max-w-[900px] flex flex-[3]">

        <Editor
            className="overflow-hidden"
            width="100%"

            height="calc(100vh - 40px)"
            onMount={onEditorDidMount}
            theme="vs-dark"
            language="json"

        />
    </div>;
}

export default AppEditorWithID;


export function createModel(name: string, monacoEditor: typeof editor, code?: string,) {
    try {
        const newModel: editor.ITextModel = monacoEditor.createModel(
            code || "",
            "json",
            Uri.parse(`file:///${name}.json`)
        );
        newModel.updateOptions(editorModelOptions);
        return newModel;
    } catch (error) {
        //
    }
}
