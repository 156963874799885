import monaco, {editor} from "monaco-editor";

import {colors} from "./colors";

export const editorModelOptions: editor.ITextModelUpdateOptions = {
    tabSize: 2,
};

export const editorOptions: monaco.editor.IEditorConstructionOptions = {
    autoClosingQuotes: "always",
    autoClosingBrackets: "always",
    minimap: {enabled: false},
    wordWrap: "on",
    scrollbar: {vertical: "hidden", useShadows: false},
    folding: false,
    occurrencesHighlight: "off",
    overviewRulerBorder: false,
    selectOnLineNumbers: false,
    overviewRulerLanes: 0,
    hideCursorInOverviewRuler: true,
    selectionHighlight: false,
    fontSize: 12,
    lineHeight: 19,
    renderLineHighlight: "gutter",
    lineDecorationsWidth: 36,
    lineNumbersMinChars: 6,
    contextmenu: false,
    roundedSelection: false,
    fontFamily: "InputMono",
    wordWrapColumn: 80,
    autoIndent: "full",
};

export const VariableTheme: editor.IStandaloneThemeData = {
    base: "vs-dark",
    inherit: false,
    rules: [
        {token: "comment", foreground: colors.editorDarkGrey},
        {token: "", foreground: colors.editorWhite}, // default
        {token: "punctuation", foreground: colors.editorLightGrey},
        {token: "meta.brace", foreground: colors.editorLightGrey},
        {
            token: "punctuation.definition.block",
            foreground: colors.editorLightGrey,
        },
        {
            token: "punctuation.definition.string",
            foreground: colors.editorDarkPurple,
        },

        {token: "entity.name.tag", foreground: colors.editorDarkPurple}, // tag
        {token: "support.class.component", foreground: colors.editorDarkPurple}, // component tag
        {token: "entity.name.function.tsx", foreground: colors.editorYellow},
        {
            token: "constant.numeric.decimal.tsx",
            foreground: colors.editorOrange,
        },

        {token: "keyword.control", foreground: colors.editorLightPurple},
        {token: "keyword.operator", foreground: colors.editorLightGrey},
        {token: "keyword.operator.new", foreground: colors.editorPink},
        {
            token: "punctuation.definition.comment",
            foreground: colors.editorDarkGrey,
        },
        {token: "string.quoted", foreground: colors.editorDarkPurple},
        {token: "storage.type", foreground: colors.editorLightBlue}, // const
        {token: "storage.modifier.async", foreground: colors.editorPink}, // async
        {token: "keyword.control.conditional", foreground: colors.editorPink}, // if, else, etc
        {token: "keyword.control.switch", foreground: colors.editorPink}, // switch, case
        {token: "keyword.control.loop", foreground: colors.editorPink}, // break
        {
            token: "storage.type.function.arrow",
            foreground: colors.editorPink,
        },
        {token: "storage.type.class", foreground: colors.editorPink}, // class
        {
            token: "entity.other.attribute-name",
            foreground: colors.editorLightPurple,
        }, // props
        {token: "variable.parameter", foreground: colors.editorWhite},
        {token: "variable.other.readwrite", foreground: colors.editorWhite},
        {token: "variable.other.readwrite.alias", foreground: colors.editorWhite},
        {token: "variable.other.property", foreground: colors.editorWhite},
        {token: "variable.other.object", foreground: colors.editorLightBlue}, // event.pageX
        {token: "variable.language.this", foreground: colors.editorDarkPurple}, // this
        {token: "constant.language", foreground: colors.editorOrange},
        {token: "constant.language.boolean", foreground: colors.editorOrange},
        {token: "constant.language.null", foreground: colors.editorOrange},
        {
            token: "constant.language.undefined",
            foreground: colors.editorOrange,
        },
        {token: "meta.delimiter.decimal", foreground: colors.editorOrange},
        {token: "keyword.control.flow", foreground: colors.editorPink}, // return
        {token: "keyword.control.export", foreground: colors.editorPink},
        {token: "keyword.control.import", foreground: colors.editorPink},
        {token: "keyword.control.from", foreground: colors.editorPink},
        {token: "keyword.control.as", foreground: colors.editorPink},
        {token: "keyword.control.default", foreground: colors.editorPink},
        {token: "variable.other.readwrite", foreground: colors.editorWhite}, // variables
        {token: "variable.other.constant", foreground: colors.editorWhite}, // variables
        {
            token: "constant.language.import-export-all",
            foreground: colors.editorYellow,
        },
        {
            token: "support.constant", // Math
            foreground: colors.editorYellow,
        },
        {
            token: "support.function", // random()
            foreground: colors.editorYellow,
        },
        {
            token: "support.constant.dom", // .createElement
            foreground: colors.editorDarkBlue,
        },
        {
            token: "support.function.dom", // .createElement
            foreground: colors.editorDarkBlue,
        },
        {
            token: "support.class.console", // console
            foreground: colors.editorYellow,
        },
        {
            token: "support.function.console",
            foreground: colors.editorYellow,
        }, // .log
        {
            token: "punctuation.definition.template-expression",
            foreground: colors.editorDarkBlue,
        },
        {token: "keyword.operator.logical", foreground: colors.editorPink}, // !, &&
        {token: "string.template", foreground: colors.editorDarkPurple}, // literals
        {
            token: "punctuation.definition.string.template",
            foreground: colors.editorDarkPurple,
        }, // literal '
        {token: "string.quoted.single", foreground: colors.editorDarkPurple}, // strings
        {
            token: "punctuation.definition.string",
            foreground: colors.editorDarkPurple,
        },
        {
            token: "punctuation.definition.template-expression",
            foreground: colors.editorYellow,
        }, // `${}`

        //
        //  ⭐️
        //  CSS
        //
        {token: "tag.css", foreground: colors.editorPink},
        {token: "entity.name.tag.css", foreground: colors.editorPink},
        {
            token: "entity.other.attribute-name.class.css",
            foreground: colors.editorPink,
        },
        {token: "delimiter.bracket.css", foreground: colors.editorLightGrey},
        {token: "delimiter.css", foreground: colors.editorLightGrey},
        {token: "attribute.name.css", foreground: colors.editorWhite},
        {
            token: "attribute.value.number.css",
            foreground: colors.editorDarkPurple,
        },
        {token: "attribute.value.unit.css", foreground: colors.editorDarkPurple},
        {token: "attribute.value.css", foreground: colors.editorYellow},
        {
            token: "constant.numeric.css",
            foreground: colors.editorDarkPurple,
        },
        {
            token: "support.constant.color.w3c-standard-color-name.css",
            foreground: colors.editorYellow,
        },
        {
            token: "keyword",
            foreground: colors.editorYellow,
        },
        {
            token: "keyword.other.unit",
            foreground: colors.editorDarkPurple,
        },
        {
            token: "keyword.other.important",
            foreground: colors.editorYellow,
        },
        {
            token: "support.constant.property-value.css",
            foreground: colors.editorYellow,
        },
        {
            token: "support.function.misc.css",
            foreground: colors.editorYellow,
        },
        {
            token: "support.constant.property-value.css",
            foreground: colors.editorYellow,
        },

        // JSON
        {
            token: "string.json",
            foreground: colors.editorDarkPurple,
        },
        {
            token: "delimiter.bracket.json",
            foreground: colors.editorLightGrey,
        },
        {
            token: "delimiter.colon.json",
            foreground: colors.editorLightGrey,
        },
        {
            token: "delimiter.comma.json",
            foreground: colors.editorLightGrey,
        },
        {
            token: "string.key.json",
            foreground: colors.editorLightPurple,
        },
        {
            token: "string.value.json",
            foreground: colors.editorDarkPurple,
        },
        {
            token: "number.json",
            foreground: colors.editorOrange,
        },
        {
            token: "keyword.json",
            foreground: colors.editorOrange,
        },
        {
            token: "string.json",
            foreground: colors.editorDarkPurple,
        },
    ],
    colors: {
        "editor.background": colors.base,
        "editorCursor.foreground": colors.editorWhite,
        "editorLineNumber.foreground": "#333333",
        "editorLineNumber.activeForeground": colors.editorLightGrey,
        "editor.lineHighlightBackground": colors.base,
        "editor.selectionBackground": "#333333",
        "editorGutter.background": colors.base,
        // 'editorBracketMatch.background': '#333333',
        // 'editorBracketMatch.border': '#333333',

        // widget
        "editorWidget.background": "#000000",
        "editorWidget.border": "#000000",
        "editorSuggestWidget.selectedBackground": "#222222", // active row in widget
        "list.hoverBackground": "#111111", // hover row in widget
        "editorSuggestWidget.highlightForeground": colors.editorWhite, // active text
        "list.hoverForeground": colors.editorWhite,
        "editorSuggestWidget.foreground": "#888888",
        "widget.shadow": "#000000",
        "input.background": "#222222",
        "input.border": "#222222",
        "input.foreground": colors.editorWhite,
        "textLink.foreground": colors.blueberry,
        "editorLink.activeForeground": colors.editorLightGrey,

        "editorBracketMatch.background": "#222222",
        "editorBracketMatch.border": colors.editorPink,

        // Bracket pair colorization (Monaco 0.33.0+)
        "editorBracketPairGuide.activeBackground1": colors.editorDarkPurple,
        "editorBracketPairGuide.activeBackground2": colors.editorPink,
        "editorBracketPairGuide.activeBackground3": colors.editorLightBlue,
        "editorBracketPairGuide.activeBackground4": colors.editorOrange,

        // Bracket pair highlighting
        "editorBracketHighlight.foreground1": colors.editorYellow,
        "editorBracketHighlight.foreground2": colors.editorPink,
        "editorBracketHighlight.foreground3": colors.editorLightBlue,
        "editorBracketHighlight.foreground4": colors.editorOrange,
        "editorBracketHighlight.unexpectedBracket.foreground": "#ff0000",
    },
};
