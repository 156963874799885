import React from "react";
import {createRoot} from "react-dom/client";


import Editor from "./Editor";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <div>Hello world!</div>,
    },
    {
        path: "/:mashupId/:componentName?",
        element: <Editor />,
    },
]);


function App() {
    return <RouterProvider router={router} />;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(<App />);
