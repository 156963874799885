export type ColorTypes =
  | "W2"
  | "W4"
  | "W6"
  | "W8"
  | "W10"
  | "W12"
  | "white"
  | "clear"
  | "baseLight"
  | "base"
  | "baseDark"
  | "primaryText"
  | "secondaryText"
  | "inactive"
  | "blueberry"
  | "blueberryHover"
  | "blueberryPressed"
  | "blueberry40"
  | "blueberryLight"
  | "plum"
  | "strawberry"
  | "strawberryHover"
  | "strawberryPressed"
  | "kiwi"
  | "dragonfruit"
  | "tangerine"
  | "marketingSecondaryText"
  | "editorOrange"
  | "editorGreen"
  | "editorDarkBlue"
  | "editorWhite"
  | "editorLightGrey"
  | "editorDarkGrey"
  | "editorLightPurple"
  | "editorPink"
  | "editorDarkPurple"
  | "editorLightBlue"
  | "editorYellow"
  | "dropShadow";

export const colors: { [key in ColorTypes]: string } = {
    W2: "rgba(255,255,255,0.02)",
    W4: "rgba(255,255,255,0.04)",
    W6: "rgba(255,255,255,0.06)",
    W8: "rgba(255,255,255,0.08)",
    W10: "rgba(255,255,255,0.10)",
    W12: "rgba(255,255,255,0.12)",
    white: "#FFFFFF",
    clear: "rgba(255,255,255,0)",

    baseLight: "#161616",
    base: "#111111",
    baseDark: "#0A0A0A",
    dropShadow: "rgba(0,0,0,0.4)",

    primaryText: "#FFFFFF",
    secondaryText: "rgba(255,255,255,0.45)",
    inactive: "rgba(255,255,255,0.33)",

    blueberry: "#0088FF",
    blueberryHover: "#0077FF",
    blueberryPressed: "#0066FF",
    blueberry40: "rgba(0,153,255,0.40)",
    blueberryLight: "#00AAFF",

    plum: "#AA44FF",

    strawberry: "#FF3366",
    strawberryHover: "#EB2657",
    strawberryPressed: "#D61A49",

    kiwi: "#22CC88",

    dragonfruit: "#FF0099",

    tangerine: "#FF8800",

    marketingSecondaryText: "#999999",

    editorWhite: "#FFFFFF",
    editorLightGrey: "#888888",
    editorDarkGrey: "#666666",

    editorDarkBlue: "#00AAFF", // functions
    editorLightBlue: "#66DDFF", // const
    editorDarkPurple: "#AA77FF", // tags
    editorLightPurple: "#FFAAFF", // props
    editorGreen: "#88FF66", // strings
    editorPink: "#FF4499", // export
    editorOrange: "#FF7766", // number
    editorYellow: "#FFDD55", // functions
};
